.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.navbar {
  display: flex;
  justify-content: space-between;
  background-color: #16a085;
  width: 100%;
  padding: 8px 0;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.navbar img {
  max-height: 40px;
  width: auto;
  margin-left: 20px;
}

.navigation ul {
  display: flex;
  gap: 15px;
  list-style: none;
}

.navigation li {
  padding: 0px 8px;
}

.navigation a {
  text-decoration: none;
  font-weight: bold;
  color: #fff;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 20px 0;
}

.message {
  width: 100%;
  padding: 8px 20px;
  border-radius: 8px;
}

.error {
  color: #ba3939;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
}

.success {
  color: white;
  background: #1EC9A755;
  border: 1px solid #1EC9A7;
}

.autocomplete-wrapper {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 5px;
  position: sticky;
  top: 60px; 
}

.autocomplete {
  border: 1px solid #6B6B6B;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 8px 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  height: 25px;
  width: 80%;
}

.autocomplete:focus {
  border: 1px solid #1EC9A7;
  outline: #1EC9A7;
}

hr.solid {
  border-top: 1px solid #bbb;
  padding: 0;
  width: 100%;
}

.results {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  list-style: none;
  padding: 0px 20px;
}

.results li {
  border-radius: 8px;
  width: 100%;
  padding: 20px 10px;
  text-align: left;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.results li:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-header {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-left: 3px solid #1EC9A7;
}

.card-header button {
  border: none;
  background-color: #16a085;
  color: #f9f9f9;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 7px;
  padding: 5px 8px;
}

.card-header button:hover {
  background-color: #1EC9A7;
}

.card-header span {
  color: #16a085;
}

.attribut {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.title {
  color: #6B6B6B;  
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.value { 
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .main {
    width: 100%;
    padding: 20px 0px;
    margin: 0;
  }

  .card-header button, .results li {
    font-size: 12px;
  }
}